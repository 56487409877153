<template>
  <div class="mx-8 sm:mx-20 py-10">
    <header class="lg:w-3/4 mx-auto relative grid grid-cols-2 mb-16 items-center sm:gap-x-12">
      <div class="flex justify-end">
        <NuxtImg alt="Dominik Fladung in einem weißen Rahmen und einer Brille" title="Dominik Fladung"
          src="/images/dominik-fladung_w.webp" sizes="192px"
          class="object-cover bg-opacity-20 flex-shrink-0 w-48 h-48 p-1 mx-auto sm:mx-0" />
      </div>
      <div class="pl-1 flex mt-8">
        <div class="text-center">
          <h1>Dominik Fladung</h1>
          <h2>IT-Consultant</h2>
        </div>
      </div>
    </header>
    <main>
      <CV />
    </main>
  </div>
  <SocialsDockbar />
</template>
