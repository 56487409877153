<template>
  <div v-if="cventries && cventries.length" class="relative lg:w-3/4 mx-auto">
    <div class="absolute top-0 bg-slate-500 bottom-0 w-1 rounded md:inset-x-1/2"></div>
    <div class="grid gap-y-8 md:grid-cols-2 py-4">
      <div
        v-for="cventry in cventries"
        :key="cventry.id"
        class="md:odd:text-right group flex md:even:mt-12 md:even:flex-row-reverse flex-row-reverse md:flex-row justify-end"
      >
        <div class="flex-grow max-w-xl">
          <div class="leading-none h-8 flex items-center md:group-odd:justify-end">
            {{ formatCVDate(cventry.startdate) }} -
            {{ cventry.enddate ? formatCVDate(cventry.enddate) : 'dato' }}
            <span class="ml-1 text-gray-400">{{ calcDuration(cventry.startdate, cventry.enddate) }}</span>
          </div>

          <div class="font-bold">{{ cventry.title }}</div>
          <div>{{ cventry.organization }}</div>
          <!-- <div class="text-gray-400" v-html="cventry.text"></div> -->
        </div>

        <div class="relative flex h-8 items-center md:group-even:flex-row-reverse flex-row-reverse md:flex-row">
          <span
            :class="{
              'opacity-0': cventry.enddate,
              'animate-pulse': !cventry.enddate,
            }"
            class="h-2 w-2 mr-2 ml-1 md:group-odd:mr-1 md:group-odd:ml-2 rounded-full bg-slate-300 inline-block"
          ></span>
          <div class="bg-slate-500 h-1 group-odd:rounded-l group-even:rounded-r w-8"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const cventries = [
            {
                "enddate": null,
                "id": "147645011",
                "organization": "codecentric AG",
                "startdate": "2024-02-01",
                "text": "<ul><li>Planung, Beratung und Umsetzung von Software-Projekten</li></ul>",
                "title": "IT Consultant",
                "category": [
                    "experience"
                ]
            },
            {
                "enddate": null,
                "id": "107533439",
                "organization": "Fachhochschule Südwestfalen",
                "startdate": "2020-08-01",
                "text": "<p><a href=\"https://www.fh-swf.de/de/studienangebot/studiengaenge/angewandte_informatik_b_sc___berufsbegleitendes_verbundstudium/index.php\" title=\"Angewandte Informatik B.Sc. (berufsbegleitendes Verbundstudium)\">berufsbegleitendes Verbundstudium</a></p>\n<p>Studienrichtungen: K&uuml;nstliche Intelligenz</p>\n<p>Wahlpflichtblock: Recht</p>",
                "title": "Angewandte Informatik B.Sc.",
                "category": [
                    "education"
                ]
            },
            {
                "enddate": "2023-12-31",
                "id": "147645002",
                "organization": "Venneker",
                "startdate": "2022-11-01",
                "text": "<ul><li> Weiterentwicklung der firmeninternen Software mit JS/CSS/C\#(ASP.Net)</li><li>Entwicklung des neuen Frontends mit Vue3/Vite/Tailwind CSS</li><li>Automatisierung der Deployment-Pipelines mittels Python</li><li>Weiterentwicklung von internen Apps mit Kotlin</li><li>Dokumentation (Markdown) der Software für Entwickler und Anwender (Vitepress)</li><li>Testen (XUnit/Cypress) der Software</li></ul>",
                "title": "Softwareentwickler",
                "category": [
                    "experience"
                ]
            },
            {
                "enddate": "2022-10-01",
                "id": "107533432",
                "organization": "FKT42 GmbH",
                "startdate": "2020-01-26",
                "text": "<ul><li>Entwicklung und Beratung von Projekten auf Auftragsbasis</li><li>PHP(Laravel)/CSS(Tailwind)/JS(Vue), Kotlin, Swift</li><li>Entwicklung eines internen CMS</li></ul>",
                "title": "Softwareentwickler",
                "category": [
                    "experience"
                ]
            },
            {
                "enddate": "2020-01-25",
                "id": "107533436",
                "organization": "FKT42 GmbH",
                "startdate": "2017-08-01",
                "text": "<ul><li>Weiter- und Neuentwicklung von Webanwendungen, native Apps für iOS/Android sowie AutoCAD-Plugins je nach Auftrag.</li><li>PHP(Laravel)/CSS(Tailwind/Bootstrap)/JS(Vue, jQuery), Kotlin, Swift</li><li>Abschluss verkürzt mit der Note “Sehr-Gut” erreicht</li></ul>",
                "title": "Ausbildung zum Fachinformatiker Anwendungsentwicklung",
                "category": [
                    "experience"
                ]
            }
]

const calcDuration = (startDateString: string, endDateString: string | null = null): string => {
  const startDate = new Date(startDateString);
  const endDate = endDateString ? new Date(endDateString) : new Date();

  if (startDate > endDate) {
    return '';
  }

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 1;
  months -= startDate.getMonth();
  months += endDate.getMonth();

  const years = Math.floor(months / 12);
  months -= years * 12;

  const yearString = years > 0 ? `${years} ${years === 1 ? 'Jahr' : 'Jahre'}` : '';
  const monthString = months > 0 ? `${months} ${months === 1 ? 'Monat' : 'Monate'}` : '';

  const duration = `${yearString} ${monthString}`.trim();
  return `(${duration})`;
};

const formatCVDate = (startDateString: string): string => {
  const startDate = new Date(startDateString);
  return startDate.getFullYear() + '/' + (startDate.getMonth() + 1);
};
</script>
